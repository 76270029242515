import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

import BlogListEntry, { PropTypeBlogEntryShape } from "./blog-list-entry";

const BlogListStyle = styled.div`
    h1 {
        font-size: 5em;
        font-weight: bold;
        margin: 1em 0;
        text-align: center;
    }
    & > ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
    }
    padding: 20px;
    min-height: 60vh;

    @media only screen and (max-width: ${({ theme }) => theme.responsiveWidthFirst}) {
        & > ul {
            grid-template-columns: 1fr;
        }
    }
`;

const BlogList = ({ blogList, locale, title, categorySlug }) => (
    <BlogListStyle>
        {title && <h1>{title}</h1>}
        <ul>
            {(blogList || []).map((blog) => (
                <BlogListEntry
                    key={blog.slug}
                    blog={blog}
                    locale={locale}
                    categorySlug={categorySlug}
                />
            ))}
        </ul>
    </BlogListStyle>
);

BlogList.propTypes = {
    locale: PropTypes.string,
    title: PropTypes.string,
    categorySlug: PropTypes.string.isRequired,
    blogList: PropTypes.arrayOf(PropTypes.shape(PropTypeBlogEntryShape)).isRequired,
    i18nData: PropTypes.shape({
        blogListTitle: PropTypes.string,
    }).isRequired,
};

BlogList.defaultProps = {
    locale: null,
    title: null,
};

export default BlogList;
