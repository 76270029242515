import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

export const PropTypeBlogEntryShape = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    headerImage: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    tags: PropTypes.string,
    credits: PropTypes.string,
};

const BlogEntryStyle = styled.div`
    a {
        display: block;
        text-decoration: none;
        color: black;
        position: relative;
        &:hover {
            .hover_image {
                background-color: aliceblue;
                filter: grayscale(10%);
                &:after {
                    opacity: 0.5;
                }
            }
        }
    }
`;

const BlogEntryTitle = styled.div`
    font-size: 1.3em;
    font-weight: bold;
    margin: 0.8rem 0;
    line-height: 1.3em;
`;

const BlogEntryDescription = styled.div`
    font-weight: normal;
    text-align: justify;
    line-height: 1.4em;
    margin: 0.8rem 0;
`;

const BlogEntryImage = styled.div`
    background-size: cover;
    min-height: 300px;
    border-radius: 10px;
    background-position: center center;
    background-color: #f2f2f2;
    background-image: url(${({ $imageSrc }) => $imageSrc});
    overflow: hidden;
    filter: grayscale(0%);
    transition: filter, background-color 0.5s ease-in-out;
    position: relative;
    &:after {
        position: absolute;
        content: " ";
        background-color: #057ce5;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5em;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
`;

const BlogEntryTagsStyle = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0.8rem 0;
    font-size: 0.8em;
    h5 {
        color: ${({ theme }) => theme.mainColor};
    }
    ul {
        display: inline-flex;
        li {
            padding: 0;
            margin: 0;
            &:before {
                padding: 0 0.25em;
                content: "\\2010";
                color: ${({ theme }) => theme.mainColor};
            }
        }
    }
`;

const BlogEntryTags = ({ tags }) => (
    <BlogEntryTagsStyle>
        <h5>Tag</h5>
        {tags.length > 0 && <ul>{tags.map((t) => t && <li key={t}>{t}</li>)}</ul>}
    </BlogEntryTagsStyle>
);

BlogEntryTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const BlogCredits = styled.div`
    font-size: 0.8em;
    opacity: 0.5;
`;

const BlogEntry = ({ blog, locale, categorySlug }) => {
    const link = locale
        ? `/${locale}/blg/${categorySlug}/${blog.slug}/`
        : `/blg/${categorySlug}/${blog.slug}/`;
    return (
        <BlogEntryStyle>
            <Link to={link}>
                <BlogEntryImage
                    className="hover_image"
                    $imageSrc={blog.headerImage ? blog.headerImage.url : ""}
                />
                <BlogEntryTags tags={(blog.tags || "").split(",").map((x) => x.trim())} />
                <BlogEntryTitle>{blog.title}</BlogEntryTitle>
                <BlogEntryDescription>{blog.description}</BlogEntryDescription>
                <BlogCredits>{blog.credits}</BlogCredits>
            </Link>
        </BlogEntryStyle>
    );
};

BlogEntry.propTypes = {
    blog: PropTypes.shape(PropTypeBlogEntryShape).isRequired,
    categorySlug: PropTypes.string.isRequired,
    locale: PropTypes.string,
};

BlogEntry.defaultProps = {
    locale: null,
};

export default BlogEntry;
