import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import DefaultTemplate from "./default-template";
import theme from "../utils/theme";
import Header from "../components/blog/header";
import CenterFrame from "../components/common/center-frame";
import BlogList from "../components/blog/blog-list";

import "../components/blog/cms";

const Inner = styled.div`
    margin: 0 0 100px 0;
`;

const BackgroundImage = styled.img`
    width: 100%;
    margin: 0;
`;

const BlogCategoryPage = ({ data, location: { pathname }, pageContext: { locale } }) => {
    const i18nData = data.allDatoCmsI18N.nodes[0];
    const { blogCategory } = data;
    return (
        <DefaultTemplate
            i18nData={i18nData}
            locale={locale}
            pathname={pathname}
            title={i18nData.blogListTitle}
            seoMetaTags={blogCategory.seoMetaTags}
        >
            <ThemeProvider theme={theme}>
                <Header />
                {blogCategory.backgroundImage && (
                    <BackgroundImage src={blogCategory.backgroundImage.url} />
                )}
                <CenterFrame>
                    <Inner>
                        <BlogList
                            categorySlug={blogCategory.slug}
                            blogList={blogCategory.entries}
                            title={blogCategory.title}
                            locale={locale}
                            i18nData={i18nData}
                        />
                    </Inner>
                </CenterFrame>
            </ThemeProvider>
        </DefaultTemplate>
    );
};

BlogCategoryPage.propTypes = {
    data: PropTypes.shape({
        allDatoCmsBlogEntry: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allDatoCmsI18N: PropTypes.shape({
            nodes: PropTypes.arrayOf(
                PropTypes.shape({
                    blogListTitle: PropTypes.string,
                })
            ),
        }),
        blogCategory: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string,
            backgroundImage: PropTypes.shape({
                url: PropTypes.string,
            }),
            entries: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    slug: PropTypes.string.isRequired,
                })
            ),
            seoMetaTags: PropTypes.shape({}),
        }),
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
};

export default BlogCategoryPage;

export const query = graphql`
    query ($locale: String, $id: String) {
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
                ...BlogData
            }
        }

        blogCategory: datoCmsBlogCategory(id: { eq: $id }, locale: { eq: $locale }) {
            title
            slug
            backgroundImage {
                url
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
            entries {
                title
                slug
                description
                tags
                credits
                headerImage {
                    url
                }
            }
        }
    }
`;
